export const recordUse = (input) => {
  const { roomUseYN, recordUseYN } = input;
  if (roomUseYN === "Y" && recordUseYN === "Y") return 1; // ⇒ 화상감독
  if (roomUseYN === "N" && recordUseYN === "Y") return 2; // ⇒ AI 감독
  if (roomUseYN === "N" && recordUseYN === "N") return 3; // ⇒ 무감독 (비화상)
  // if(roomUseYN === "N" && recordUseYN===  "Y")  // ⇒ ⚠️ **에러 CASE**
};

export const recordUseSubmit = (type) => {
  if (type === 1) return { roomUseYN: "Y", recordUseYN: "Y" }; // ⇒ 화상감독
  if (type === 2) return { roomUseYN: "N", recordUseYN: "Y" }; // ⇒ AI 감독
  if (type === 3) return { roomUseYN: "N", recordUseYN: "N" }; // ⇒ 무감독 (비화상)
  // if(roomUseYN === "N" && recordUseYN===  "Y")  // ⇒ ⚠️ **에러 CASE**
};
