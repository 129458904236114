import { Badge, Grid, Typography } from "@mui/material";
import React, { memo, useMemo } from "react";
import { recordUse } from "utils/recordUse";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

// 상수로 스타일 분리
const STYLES = {
  photoCamera: {
    color: "#3e6ad1"
  },
  noPhotography: {
    color: "#ff6594"
  },
  aiTypography: {
    fontWeight: 900,
    fontSize: 10,
    color: "#a30e4f"
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "4px"
  }
};

export const RecordYN = memo(({ row }) => {
  // recordUse 결과 메모이제이션
  const type = useMemo(() => recordUse(row), [row]);
  
  // 조건부 렌더링 로직 메모이제이션
  const content = useMemo(() => {
    switch (type) {
      case 1:
        return (
          <Grid sx={STYLES.gridContainer}>
            <PhotoCameraIcon sx={STYLES.photoCamera} />
          </Grid>
        );
      case 2:
        return (
          <Badge
            badgeContent={
              <Typography sx={STYLES.aiTypography}>
                AI
              </Typography>
            }
          >
            <NoPhotographyIcon sx={STYLES.noPhotography} />
          </Badge>
        );
      default:
        return <NoPhotographyIcon sx={STYLES.noPhotography} />;
    }
  }, [type]);

  return content;
});

// 컴포넌트 이름 지정
RecordYN.displayName = 'RecordYN';