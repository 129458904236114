import React, { useEffect, useMemo, useState } from "react";

// Style
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactComponent as ConfirmCircle } from "assets/icon/confirmCircle.svg";
import { ReactComponent as Spinner } from "assets/icon/SvgSpinnersBlocksWave.svg";
import { X } from "lucide-react";

// Hooks
import useGetFile from "hooks/useGetFile";
import useMutationHook from "hooks/useMutationHook";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            "&": {
              background: "#eaeaea",
            },
            "&>.MuiLinearProgress-barColorPrimary": {
              background: "linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);",
            },
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const DownloadProgressModal = ({ onClose, id, mutateId, queryId }) => {
  const [toggle, setToggle] = useState(false);
  const [targetProgress, setTargetProgress] = useState(0);
  const [isProgressDone, setIsProgressDone] = useState(false);

  const payload = useMemo(() => {
    if (queryId === "reportDownloadPersonal" && mutateId === "personalHRreport") return "PERSONAL";
    else if (queryId === "reportDownloadInterviewer" && mutateId === "interviewerReport") return "INTERVIEW";
  }, [mutateId, queryId]);

  //*   모든 개인 레포트 탬플릿 생성
  const { mutate, isError } = useMutationHook(mutateId, "HR");

  const { data, isFetching: downloadReport_isFetching } = useGetFile("HR", queryId, { groupIdx: id, reportType: payload }, toggle, setToggle);

  const isSuccess = (res) => {
    const { progressNo, totalProgress } = res.data.data;
    setTargetProgress(Math.round((progressNo / totalProgress) * 100));

    if (progressNo >= totalProgress) {
      setIsProgressDone(true);
      return;
    }

    mutate(
      {
        groupIdx: Number(id),
        progressNo: progressNo + 1,
        reportType: payload,
      },
      {
        onSuccess: (res) => {
          isSuccess(res);
        },
      }
    );
  };

  const handleTriggerDownload = () => {
    if (isError) onClose();
    else setToggle(true);
  };

  useEffect(() => {
    mutate(
      {
        groupIdx: Number(id),
        progressNo: 1,
        reportType: payload,
      },
      {
        onSuccess: (res) => {
          isSuccess(res);
        },
      }
    );
  }, []);

  useEffect(() => {
    data && data.status === 200 && onClose();
  }, [data]);

  const handleClose = () => {
    onClose();
  };

  const message = useMemo(() => {
    if (isError) {
      return <Typography>❌ 다운로드를 실패하였습니다.</Typography>;
    }

    if (targetProgress < 100) {
      return (
        <Grid>
          <Grid item container justifyContent={"center"} mb={2}>
            <Spinner />
          </Grid>
          <Typography fontSize="12px" color={"#777"}>
            응시자 정보를 취합중입니다. 잠시만 기다려 주세요..
          </Typography>
          <LinearProgressWithLabel value={targetProgress} />
        </Grid>
      );
    } else {
      return (
        <Grid>
          <Grid item container mb={2} alignItems="center" columnGap={1} flexWrap="nowrap">
            <Grid item container flexDirection="column">
              <Grid item container alignItems="center" columnGap={1}>
                <ConfirmCircle />
                <Typography fontWeight={700}>다운로드가 완료되었습니다.</Typography>
              </Grid>
              <Typography color={"#181f62"} fontSize="12px" whiteSpace="pre-wrap" mt={2} ml={4}>
                {`아래 '확인'버튼을 누르면 다운로드됩니다.`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }, [targetProgress, isError]);

  return (
    <>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">템플릿 다운로드</Typography>
          <X onClick={handleClose} style={{ cursor: "pointer", color: "#575757" }} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid pt={2}>
          {message}
          <Grid container justifyContent={"flex-end"} mt={3}>
            <Button variant="contained" onClick={handleTriggerDownload} disabled={!isProgressDone}>
              {downloadReport_isFetching ? <Spinner /> : "확인"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export const DownloadProgressModalMemoized = React.memo(DownloadProgressModal);
