import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";

// Style
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { checkIcon, questionIcon } from "assets/icon/Icon_modal";
import { Trash2 } from "lucide-react";
import { tableHeader } from "themes/theme";
// Components
import TopTitle from "components/templates/topTitle/TopTitle";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
// Recoil
import { useRecoilState } from "recoil";
import { perPageAtom } from "recoil/store";

// React-query
import { useQueryClient } from "@tanstack/react-query";
import useGetQuery from "hooks/useGetQuery";
import useMutationHook from "hooks/useMutationHook";

// Hooks
import { useContentEditable } from "hooks/useContentEditable";
// Dialog
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import Swal from "sweetalert2";

//ENUM
import { MESSAGE_COPY_TERM, MESSAGE_UPDATE_TERM } from "enums/modalMessage";
import { TERMS_COL } from "enums/tableColumn";

// Libraries
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// Utils
import { Checkbox, Collapse, FormControl, FormControlLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { dateFormat } from "utils/formatter/dateFormat";
import { toImgURL } from "utils/extractImgURL";

//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

const TermDeleteConfirmModal = lazy(() => import("components/pages/acg_admin/terms/TermDeleteComfirmModal"));

export default function ManageTerms() {
  const [termsName, setTermsName] = useState("");

  const [paramValues, setParamValues] = useState({
    page: { pageNo: 1 },
    search: { termsName: "" },
    perPage: 20,
  });

  const [termsIdx, setTermsIdx] = useState(0);

  const [isTermDelete, setIsTermDelete] = useState(false);
  const [selectInfo, setSelectInfo] = useState();
  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  const [isContentEditable, setIsContentEditable] = useState(false);
  const queryClient = useQueryClient();
  const termsNameRef = useRef(termsName);

  const termsContentRef = useRef();

  const termsIdxRef = useRef();
  const languageRef = useRef();

  const [currentTerm, setCurrentTerm] = useState({});

  const searchRef = useRef();
  const { data, pageInfo, isLoading, isError, refetch, isFetching } = useGetQuery("totalTerms", paramValues);

  const { content, _setContent, onInput } = useContentEditable("");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    window.location.reload();
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  useEffect(() => {
    setPerPage(20);
  }, []);
  //*  Mutation
  const { mutate } = useMutationHook("updateTerms");
  const { mutate: copyTerm } = useMutationHook("copyTerm");
  const { mutate: uploadPILOTimg } = useMutationHook("uploadPILOTimg");

  const submitSearch = () => {
    setParamValues((prev) => ({
      ...prev,
      search: { termsName: searchRef.current.value || "" },
    }));
  };

  const handleSetNowTerm = (row) => {
    setCurrentTerm(row);

    _setContent(row.termsName);
    setTermsName((prev) => ({
      ...prev,
      termsName: row.termsName,
    }));
    setTermsIdx((prev) => ({
      ...prev,
      termsIdx: row.termsIdx,
    }));
  };

  const handleDeleteTerms = () => setIsTermDelete(!isTermDelete);

  const handleCopyTerm = (_, row) => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_COPY_TERM,
        title: "약관 복사",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        copyTerm(
          { termsIdx: row[0].termsIdx },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "약관 복사가 완료되었습니다.",
                  title: "약관 복사",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalTerms");
              });
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    if (Object.keys(currentTerm).length === 0) {
      setIsContentEditable(false);
    } else {
      setIsContentEditable(true);
    }
  }, [currentTerm]);

  const [opened, setOpened] = useState({
    privacy: false,
    secret: false,
    notice: false,
    pilot: false,
  });

  const handleChange = (name) => {
    setOpened((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      examPrivacy: "",
      examSecret: "",
      examNotice: "",
      loginPopUpTitle: "",
      loginPopUpBody: "",
      loginPopUpUseYN: false,
      loginPopUpEmail: "",
      loginPopUpQNA: "",
      language: currentTerm.language,
    },
  });

  useEffect(() => {
    termsNameRef.current = termsName;

    languageRef.current = currentTerm.language;
    termsIdxRef.current = currentTerm.termsIdx;
    reset({
      examPrivacy: currentTerm.examPrivacy,
      examSecret: currentTerm.examSecret,
      examNotice: currentTerm.examNotice,
      loginPopUpTitle: currentTerm.loginPopUpTitle,
      loginPopUpBody: currentTerm.loginPopUpBody,
      loginPopUpUseYN: currentTerm.loginPopUpUseYN === "Y" ? true : false,
      loginPopUpEmail: currentTerm.loginPopUpEmail,
      loginPopUpQNA: currentTerm.loginPopUpQNA,
      language: currentTerm.language,
    });
  }, [currentTerm, reset, termsName]);

  const onSubmit = (data) => {
    const temp = { ...data };
    temp.loginPopUpUseYN = data.loginPopUpUseYN ? "Y" : "N";
    temp.termsName = content;
    temp.language = currentTerm.language;

    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_UPDATE_TERM,
        title: "약관 수정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          { termsIdx: termsIdx, submitData: temp },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "약관 수정이 완료되었습니다.",
                  title: "약관 수정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalTerms");
              });
            },
          }
        );
      }
    });
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.addEventListener("change", async () => {
      const file = input.files[0];
      const formDataPILOTimg = new FormData();
      formDataPILOTimg.append("termsImg", file);
      uploadPILOTimg(
        {
          language: languageRef.current,
          termsIdx: termsIdxRef.current,
          termsImg: formDataPILOTimg,
        },
        {
          onSuccess: (res) => {
            const editor = termsContentRef.current.getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index, "image", toImgURL(res.data.data));
          },
        }
      );
    });
  };
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [[{ align: [] }], ["image"], [{ header: [1, 2, 3, 4, 5, 6, false] }], ["bold", "italic", "underline", "strike", "blockquote"]],
        handlers: {
          image: () => imageHandler(),
        },
      },
    };
  }, []);

  return (
    <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
      <TopTitle
        list={[
          {
            title: "약관 관리",
            url: `/terms`,
            current: true,
          },
        ]}
      />

      <Grid
        container
        bgcolor="white"
        p={2}
        borderRadius={2}
        columnGap={2}
        justifyContent="space-between"
        flexWrap="nowrap"
        height={"calc(100vh - 48px)"}
        overflow={"hidden"}
      >
        <Grid item width="60%" overflow={"scroll"}>
          <Box>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container justifyContent={"center"} alignItems="center" columnGap={1} mb={3} borderRadius={2} p={3} bgcolor="#eeeeee">
                <Typography contentEditable={isContentEditable} suppressContentEditableWarning variant="h6" onInput={onInput}>{`${
                  termsName.termsName || "약관을 선택해 주세요"
                }`}</Typography>
              </Grid>

              {/* 1 of TERMS  */}

              <Grid container rowGap={2} direction="column">
                <Box>
                  <Grid container onClick={() => handleChange("privacy")} alignItems={"center"} mb={0.5}>
                    {opened.privacy ? <ArrowDropDownIcon sx={{ fontSize: 25 }} /> : <ArrowRightIcon sx={{ fontSize: 25 }} />}
                    <Typography fontWeight={700} variant="h6" sx={{ lineHeight: 0 }}>
                      개인정보 이용 수집 동의
                    </Typography>
                  </Grid>

                  <Collapse in={opened.privacy}>
                    <Controller
                      name="examPrivacy"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={onChange}
                          placeholder="내용을 입력해 주세요"
                          style={{
                            border: "1px solid #bfbfbf",
                            // overflow: "auto",
                            resize: "vertical",
                            minHeight: 150,
                          }}
                        />
                      )}
                    />
                  </Collapse>
                </Box>
                <Box>
                  <Grid container onClick={() => handleChange("secret")} alignItems={"center"} mb={0.5}>
                    {opened.secret ? <ArrowDropDownIcon sx={{ fontSize: 25 }} /> : <ArrowRightIcon sx={{ fontSize: 25 }} />}
                    <Typography fontWeight={700} variant="h6" sx={{ lineHeight: 0 }}>
                      정보 보안 서약
                    </Typography>
                  </Grid>

                  <Collapse in={opened.secret}>
                    <Controller
                      name="examSecret"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={onChange}
                          placeholder="내용을 입력해 주세요"
                          style={{
                            border: "1px solid #bfbfbf",
                            // overflow: "auto",
                            resize: "vertical",
                            minHeight: 150,
                          }}
                        />
                      )}
                    />
                  </Collapse>
                </Box>
                <Box>
                  <Grid container onClick={() => handleChange("notice")} alignItems={"center"} mb={0.5}>
                    {opened.notice ? <ArrowDropDownIcon sx={{ fontSize: 25 }} /> : <ArrowRightIcon sx={{ fontSize: 25 }} />}
                    <Typography fontWeight={700} variant="h6" sx={{ lineHeight: 0 }}>
                      응시자 유의사항
                    </Typography>
                  </Grid>

                  <Collapse in={opened.notice}>
                    <Controller
                      name="examNotice"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={onChange}
                          placeholder="내용을 입력해 주세요"
                          style={{
                            border: "1px solid #bfbfbf",
                            // overflow: "auto",
                            resize: "vertical",
                            minHeight: 150,
                          }}
                        />
                      )}
                    />
                  </Collapse>
                </Box>

                <Grid container onClick={() => handleChange("pilot")} alignItems={"center"} mb={0.5}>
                  {opened.pilot ? <ArrowDropDownIcon sx={{ fontSize: 25 }} /> : <ArrowRightIcon sx={{ fontSize: 25 }} />}
                  <Typography fontWeight={700} variant="h6" sx={{ lineHeight: 0 }}>
                    PILOT 설정
                  </Typography>
                </Grid>
                <Collapse in={opened.pilot}>
                  <Stack pl={3} rowGap={3}>
                    <Box>
                      <Controller
                        name="loginPopUpUseYN"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <FormControl component="fieldset">
                            <FormControlLabel
                              control={<Checkbox checked={value} onChange={onChange} inputRef={ref} />}
                              label="PILOT 안내문 사용"
                              labelPlacement="start"
                              sx={{ marginLeft: 0 }}
                            />
                          </FormControl>
                        )}
                      />
                    </Box>
                    <Box>
                      <Stack>
                        <Typography fontWeight={600} fontSize={"small"} mb={0.5}>
                          담당자 이메일
                        </Typography>

                        <Controller
                          name="loginPopUpEmail"
                          control={control}
                          render={({ field }) => (
                            <TextField {...field} type="email" size="small" placeholder="담당자 이메일을 입력해 주세요" sx={{ width: 200 }} />
                          )}
                        />
                      </Stack>
                    </Box>
                    <Box>
                      <Typography fontWeight={600} fontSize={"small"} mb={0.5}>
                        안내문 제목
                      </Typography>

                      <Controller
                        name="loginPopUpTitle"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={onChange}
                            placeholder="안내문 제목을 입력해 주세요"
                            style={{
                              border: "1px solid #bfbfbf",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight={600} fontSize={"small"} mb={0.5}>
                        안내문 본문
                      </Typography>

                      <Controller
                        name="loginPopUpBody"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            theme="snow"
                            value={value}
                            modules={modules}
                            onChange={onChange}
                            ref={termsContentRef}
                            placeholder="안내문 본문 내용을 입력해 주세요"
                            style={{
                              border: "1px solid #bfbfbf",
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <Typography fontWeight={600} fontSize={"small"} mb={0.5}>
                        Q&A
                      </Typography>

                      <Controller
                        name="loginPopUpQNA"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={onChange}
                            placeholder="Q&A 내용을 입력해 주세요"
                            style={{
                              border: "1px solid #bfbfbf",
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </Collapse>
              </Grid>
              <Grid container justifyContent={"center"} mt={4}>
                <Button
                  type="submit"
                  variant="contained"
                  // onClick={handleUpdateTerms}
                >
                  수정
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Divider orientation="vertical" flexItem />
        {/* 2열 */}
        <Grid item width="40%">
          {/* <Box> */}
          <Grid container justifyContent={"flex-end"} columnGap={1}>
            <TextField size="small" placeholder="약관 검색" inputRef={searchRef} />
            <Button size="small" variant="contained" onClick={submitSearch}>
              검색
            </Button>
          </Grid>
          <Grid container my={2} justifyContent="space-between" alignItems={"flex-end"}>
            <Typography color="#777">
              새 약관을 추가하려면 복사하기 (
              <ContentCopyRoundedIcon color="#777" fontSize="small" />) 버튼을 클릭하세요.
            </Typography>
            <Typography variant="h6">{`총 ${pageInfo.total}개`}</Typography>
          </Grid>

          {isFetching || isLoading ? (
            <LoadingSkeleton />
          ) : (
            <>
              {/* 테이블 */}
              <Grid container flexDirection={"row"} mb={2} rowGap={1}>
                {data &&
                  data.termsListGroupByTermsIdx.map((item, index) => {
                    return (
                      <Accordion
                        key={index}
                        elevation={0}
                        sx={{
                          bgcolor: "#f4f4f4",
                          borderRadius: 1,
                          width: "100%",
                          "&::before": {
                            background: "transparent",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          sx={{
                            "&.Mui-expanded": { minHeight: 40 },
                            "&.Mui-expanded > div": { my: 1 },
                          }}
                        >
                          <Stack direction="row" alignItems="center" columnGap={1}>
                            <Typography color={"#1E283D"} fontWeight={700}>
                              {item[0]?.termsName.replace("(국문)", "")}
                            </Typography>
                            <Tooltip title="약관복사" placement="top-start" arrow>
                              <IconButton onClick={(e) => handleCopyTerm(e, item)} size="small">
                                <ContentCopyRoundedIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            <IconButton
                              size="small"
                              onClick={() => {
                                setSelectInfo(item[0]);
                                handleDeleteTerms();
                              }}
                              sx={{
                                background: "#ffe8db",
                                color: "#f97c34",
                                "&>svg": {
                                  width: "1.1rem",
                                  height: "1.1rem",
                                },
                              }}
                            >
                              <Trash2 />
                            </IconButton>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            bgcolor: "#fff",
                            border: "1px solid #f4f4f4",
                            borderBottomLeftRadius: 7,
                            borderBottomRightRadius: 7,
                          }}
                        >
                          <Box>
                            <Paper variant="outlined">
                              <TableContainer sx={{ height: "87%", maxHeight: "87%" }}>
                                <Table size="small" stickyHeader sx={tableHeader}>
                                  <TableHead>
                                    <TableRow>
                                      {TERMS_COL.map((item) => {
                                        return (
                                          <TableCell
                                            key={item.label}
                                            sx={{
                                              width: item.size,
                                              fontWeight: "bold",
                                            }}
                                            align="center"
                                          >
                                            {item.label}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {item &&
                                      item.map((row, index) => (
                                        <TableRow
                                          key={index}
                                          sx={{
                                            "&:last-child td, &:last-child th": {
                                              border: 0,
                                            },
                                          }}
                                          role="checkbox"
                                          tabIndex={-1}
                                        >
                                          <TableCell padding="none" align="center">
                                            {index + 1}
                                          </TableCell>
                                          <TableCell padding="none" align="center" sx={{ cursor: "pointer" }} onClick={() => handleSetNowTerm(row)}>
                                            <Grid container justifyContent={"center"} columnGap={2} alignItems="center">
                                              <Typography variant="body2">{row.termsName}</Typography>
                                            </Grid>
                                          </TableCell>
                                          <TableCell>{row.language}</TableCell>

                                          <TableCell padding="none" align="center">
                                            {dateFormat(row.createdAt)}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Paper>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </Grid>
            </>
          )}
        </Grid>

        {/* CONFIRM DELETE */}
        <Dialog maxWidth="xs" fullWidth open={isTermDelete} onClose={handleDeleteTerms}>
          <Suspense fallback={<LoadingCircle />}>
            <TermDeleteConfirmModal detail={selectInfo} onClose={handleDeleteTerms} />
          </Suspense>
        </Dialog>
      </Grid>
    </Box>
  );
}
