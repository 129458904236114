// ================== QNA ==================
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, Tooltip, Typography } from "@mui/material";

export const QnA_DETAIL = [
  { label: "유형", column: "category" },
  { label: "등록일", column: "createdAt" },
  { label: "답변작성일", column: "updatedAt" },
  { label: "답변여부", column: "replySuccessYN" },
  { label: "담당자", column: "adminName" },
  { label: "성명", column: "testerName" },
  { label: "이메일", column: "testerEmail" },
  { label: "답변형식", column: "replyUse" },
  { label: "질문내용", column: "text" },
  { label: "답변내용", column: "replyText" },
];

export const QnA_REPLY_MODAL = [
  { label: "유형", column: "category" },
  { label: "등록일", column: "createdAt" },
  { label: "성명", column: "testerName" },
  { label: "이메일", column: "testerEmail" },
  { label: "휴대전화 번호", column: "testerCell" },
  { label: "질문내용", column: "text" },
  { label: "답변방식", column: "replyUse" },
  { label: "답변내용", column: "replyText" },
];

export const QnA_LIST_SEARCH = [
  { label: "회사명", value: "companyName" },
  { label: "내용", value: "text" },
  { label: "담당자", value: "adminName" },
  { label: "성명", value: "testerName" },
  { label: "이메일", value: "testerEmail" },
  { label: "휴대전화 번호", value: "testerCell" },
];

export const QnA_CATEGORY = [
  { value: "", label: "전체" },
  { value: "LOGIN", label: "로그인/접속" },
  { value: "AROUND", label: "주변기기" },
  { value: "PROGRAM", label: "프로그램" },
  { value: "ETC", label: "기타" },
];

export const QnA_IS_ANSWERED = [
  { label: "전체", value: "" },
  { label: "답변 미완료", value: "N" },
  { label: "답변 완료", value: "Y" },
];

export const QnA_REPLY_USE = [
  { label: "이메일", value: "email" },
  { label: "SMS", value: "sms" },
];

export const QnA_TABLE_ROW = [
  { label: "No", size: 30 },
  { label: "회사명", size: 65 },
  { label: "유형", size: 50 },
  { label: "내용", size: 100 },
  { label: "이메일", size: 50 },
  { label: "성명", size: 55 },
  { label: "휴대전화 번호", size: 100 },
  { label: "담당자", size: 57 },
  { label: "등록일", size: 70 },
  { label: "답변작성", size: 30 },
];

export const QnA_DELETE_EVENT_REASON = [{ value: "질문 입출력 시스템 오류" }, { value: "욕설 및 비방의 내용" }, { value: "직접 입력" }];

// ================== GroupList ==================

export const GROUP_SUMMARY_CARD = [
  { value: "companyName", label: "회사명" },
  { value: "groupName", label: "공고명" },
  { value: "normName", label: "검사 SET" },
  { value: "examSAt", label: "검사일" },
  { value: "total", label: "검사인원" },
];

export const GROUP_TABLE_ROW = [
  { id: "no", label: "GIDX", size: 40 },

  { id: "groupStatus", label: "진행현황", size: 90 },
  { id: "companyName", label: "회사명", size: 110, flag: true },
  { id: "groupId", label: "공고코드", size: 80 },
  { id: "groupName", label: "공고명", size: 250 },

  {
    id: "examSAt",
    label: (
      <Stack direction="row" alignItems="center" justifyContent="center" columnGap={1}>
        <Tooltip title="시간위에 마우스를 올리면 '로그인 가능시간' 이 나타납니다." placement="top" arrow>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
        <Typography variant="body2" fontWeight={700}>
          검사 시작
        </Typography>
      </Stack>
    ),
    size: 80,
  },

  { id: "examEAt", label: "검사 종료", size: 80 },
  // { id: "examMAP", label: "검사유형", size: 30 },
  { id: "roomUseYN", label: "화상", size: 30 },
  { id: "total", label: "총원", size: 30 },
  { id: "preDone", label: "사전점검", size: 30 },
  { id: "done", label: "완료", size: 30 },
  { id: "inProgress", label: "진행중", size: 30 },
  { id: "modify", label: "수정", size: 40 },
  { id: "approveYN", label: "검사확정", size: 40 },
];

export const REMOVED_GROUP_TABLE_ROW = [
  { label: "No" },
  // { label: "테스트공고" },
  { label: "진행현황" },
  { label: "회사명", flag: true },
  { label: "공고명" },
  { label: "화상" },
  { label: "검사 시작" },
];

export const PILOT_GROUP_TABLE_ROW = [
  { label: "No", size: 40 },
  { label: "진행현황", size: 60 },
  { label: "회사명", size: 100, flag: true },
  { label: "공고명", size: 210 },
  { label: "검사구분", size: 100 },
  { label: "사전검사 시작", size: 100 },
  { label: "본 검사 시작", size: 80 },
  { label: "수정", size: 40 },
  { label: "화상", size: 40 },
  { label: "룸 갯수", size: 45 },
  { label: "총원", size: 50 },
  { label: "완료", size: 30 },
  { label: "진행중", size: 50 },
  { label: "미응시", size: 40 },
  { label: "사전점검", size: 55 },
];

export const GROUP_CONFIRM_STATUS = [
  { label: "전체", value: "TOTAL" },
  { label: "확정", value: "Y" },
  { label: "미확정", value: "N" },
];

export const GROUP_STATUS = [
  { label: "전체", value: "TOTAL" },
  { label: "사전검사 대기", value: "PRE_WAIT" },
  { label: "사전검사 진행", value: "PRE_INPROGRESS" },
  { label: "검사 대기", value: "EXAM_WAIT" },
  { label: "검사 진행", value: "EXAM_INPROGRESS" },
  { label: "검사 종료", value: "EXAM_DONE" },
];

export const GROUP_LIST_SEARCH = [{ value: "groupName", label: "공고명" }];

export const GROUP_ROOM_LIST = [
  { size: 60, label: "화상룸" },
  { size: 70, label: "감독관" },
  { size: 78, label: "감독관 번호" },
  { size: 70, label: "인증키" },
  { size: 60, label: "총원" },
  { size: 67, label: "사전점검" },
  { size: 50, label: "완료" },
  { size: 58, label: "진행중" },
  { size: 58, label: "미완료" },
];
export const GROUP_ROOM_TESTER_LIST = [
  { size: 20, label: "" },
  { size: 30, label: "직군" },
  { size: 40, label: "성명" },
  { size: 40, label: "생년월일" },
  { size: 80, label: "휴대전화 번호" },
  { size: 40, label: "사전점검" },
  { size: 40, label: "해외응시" },
  { size: 40, label: "출석" },
  { size: 40, label: "AI 감지" },
  { size: 90, label: "최종 로그인 시간" },
  { size: 180, label: "검사 SET" },
  { size: 40, label: "응시현황" },
  // { size: 60, label: "통화상태" },
  { size: 40, label: "더보기" },
];
export const GROUP_ROOM_LIST_VIDEO = [
  { size: 60, label: "방번호" },
  { size: 60, label: "성명" },
  { size: 80, label: "생년월일" },
  { size: 100, label: "휴대전화 번호" },
  { size: 60, label: "사전점검" },
  { size: 60, label: "해외응시" },
  { size: 60, label: "출석" },
  { size: 90, label: "최종 로그인 시간" },
  { size: 60, label: "응시현황" },
  { size: 30, label: "더보기" },
];
export const GROUP_ROOM_LIST_NO_VIDEO = [
  { size: 30, label: "직군" },
  { size: 60, label: "성명" },
  { size: 40, label: "생년월일" },
  { size: 80, label: "휴대전화 번호" },
  { size: 30, label: "해외응시" },
  { size: 60, label: "AI 감지" },
  { size: 90, label: "최종 로그인 시간" },
  { size: 200, label: "검사 SET" },
  { size: 30, label: "응시 현황" },
  { size: 30, label: "더보기" },
];
export const LOGIN_LOG_DATA = [
  { size: 60, label: "인증키" },
  { size: 80, label: "접속환경" },
  { size: 100, label: "최근 로그인 시간" },
  { size: 50, label: "IP주소" },
  { size: 60, label: "네트워크 환경" },
  { size: 60, label: "로그인 여부" },
];
export const VIDEO_LOG_DATA = [
  { size: 60, label: "분류" },
  { size: 80, label: "녹화시간" },
];

export const GROUP_TESTER_LIST_MORE_LIST = [
  { index: 1, label: "응시자 정보 수정" },
  { index: 2, label: "응시 현황 수정" },
  { index: 3, label: "신분증 확인" },
  { index: 4, label: "접속내역 확인" },
  { index: 5, label: "녹화영상 확인" },
  { index: 6, label: "채팅내용 확인" },
  { index: 7, label: "개인 Report 확인" },
  { index: 8, label: "면접관 Report 확인" },
];
export const GROUP_TESTER_LIST_MORE_LIST_GUEST = [
  { index: 1, label: "응시자 정보 수정" },
  { index: 7, label: "개인 Report 확인" },
];

// ================== CompanyList ==================

export const COMPANY_LIST_MAIN = [
  { label: "진행상황", size: 50 },
  { label: "회사명", size: 280 },
  { label: "대표번호", size: 150 },
  { label: "PILOT", size: 40 },
  { label: "담당자", size: 80 },
  { label: "검사 SET", size: 80 },
  { label: "UI 템플릿", size: 80 },
  { label: "수정", size: 80 },
  { label: "채팅템플릿", size: 80 },
  { label: "등록일", size: 100 },
];
export const COMPANY_USER_LIST = [{ label: "권한" }, { label: "성명" }, { label: "휴대전화 번호" }, { label: "최근 로그인 시간" }];

export const COMPANY_DELETE_EVENT_REASON = [{ value: "입출력 시스템 오류" }, { value: "계약철회" }, { value: "직접 입력" }];

export const TERMS_COL = [
  { size: 45, label: "NO" },
  { size: 400, label: "약관목록" },
  { size: 70, label: "언어" },
  { size: 90, label: "등록일" },
];

export const TERMS_DELETE_EVENT_REASON = [{ value: "그냥" }, { value: "안씀" }, { value: "철회" }];

export const HR_MANAGE_COL = [
  { size: 30, label: "권한" },
  { size: 30, label: "성명" },
  { size: 100, label: "휴대전화 번호" },
  { size: 100, label: "직통번호" },
  { size: 100, label: "이메일" },
  { size: 100, label: "최근 로그인 시간" },
  { size: 30, label: "삭제" },
];

export const HR_MANAGE_WAITING_COL = [
  { size: 50, label: "권한" },
  { size: 50, label: "성명" },
  { size: 100, label: "휴대전화 번호" },
  { size: 100, label: "직통번호" },
  { size: 100, label: "이메일" },
  { size: 30, label: "수정" },
  { size: 30, label: "삭제" },
];

export const SEARCH_USER = [
  { label: "" },
  { label: "공고명" },
  { label: "검사 시작 일시" },
  { label: "검사 종료 일시" },
  // { label: "화상룸" },
  { label: "회사명" },
  { label: "성명" },
  { label: "생년월일" },
  { label: "휴대전화 번호" },
  { label: "검사 SET" },
  { label: "직군" },
  { label: "응시 현황" },
  // { label: "사전점검" },
  { label: "해외응시" },
  // { label: "출석" },
  { label: "수정" },
  // { size: 40, label: "복사" },
];
export const SEARCH_USER_GUEST = [
  { label: "" },
  { label: "공고명" },
  { label: "검사 시작 일시" },
  { label: "검사 종료 일시" },
  { label: "회사명" },
  { label: "성명" },
  { label: "생년월일" },
  { label: "휴대전화 번호" },
  { label: "검사 SET" },
  { label: "직군" },
  { label: "응시 현황" },
  { label: "해외응시" },
  { label: "수정" },
  // { size: 40, label: "복사" },
];

export const TESTER_STATUS = [
  { label: "검사영역" },
  { label: "남은 시간" },
  { label: "검사 시간" },
  { label: "응시 현황" },
  { label: "완료 시간" },

  // { size: 40, label: "복사" },
];

// ================== PILOT TEST PAGE ==================

export const PILOT_LIST_MAIN = [{ label: "진행상황", size: 100 }, { label: "테스트명" }, { label: "로그인" }, { label: "수정" }, { label: "등록일" }];

//* 예제문항 그룹
export const PRACTICE_GROUP_LIST = [
  { size: 50, label: "No" },
  { size: 100, label: "구분" },
  { label: "검사영역" },
  { label: "검사 SET" },
  { label: "문항 수" },
  { label: "검사 시간" },
  { label: "등록일" },
  { label: "수정" },
];

//* 문항 그룹
export const QUESTION_GROUP_LIST = [
  { size: 20, label: "No" },
  { size: 100, label: "구분" },
  { label: "검사 SET" },
  { label: "검사영역" },
  { label: "문항 수" },
  { label: "등록일" },
  { label: "수정" },
];

//* 과목관리 그룹
export const SUBJECT_GROUP_LIST = [
  { size: 50, label: "No" },
  { size: 100, label: "구분" },
  { label: "과목명" },
  { label: "세부과목명" },
  { label: "문항수" },
  { label: "제한시간 (분)" },
  { label: "등록일" },
  { label: "수정" },
];

//* 통계페이지
export const STATS_DETAIL_PAGE_TABLE = [{ label: "No" }, { label: "ID" }, { label: "응시자명" }, { label: "휴대전화 번호" }, { label: "검사 완료여부" }];

export const STATS_TABLE = [
  { size: 10, label: "No" },
  { size: 45, label: "진행현황" },
  { size: 50, label: "회사명" },
  { size: 130, label: "공고명" },
  { size: 60, label: "검사 시작" },
  { size: 60, label: "검사 종료" },
  { size: 40, label: "검사유형" },
  { size: 20, label: "화상" },
  { size: 20, label: "총원" },
  { size: 20, label: "완료" },
];

export const STATS_SUMMARY_TABLE = [{ label: "No" }, { label: "회사명" }, { label: "참여율" }, { label: "완료" }, { label: "총원" }];

export const STATS_MANAGER_LIST = [
  { label: "No" },
  { label: "권한" },
  { label: "담당자이름" },
  { label: "휴대전화 번호" },
  { label: "직통번호" },
  { label: "이메일" },
  { label: "최근 로그인 시간" },
];

export const STATS_TESTER_LIST = [{ label: "No" }, { label: "ID" }, { label: "응시자명" }, { label: "휴대전화뻔호" }, { label: "본검사" }];
